import { graphql, useStaticQuery } from 'gatsby';
import {
  formatBlogPost,
  formatCaseStudy,
  formatVideoTemplate,
  formatDatoResource,
} from '../components/ListingGenerator/ResourcesListing/utils/formatting';
import manualResources from '../components/ListingGenerator/ResourcesListing/utils/manualResources.json';

const useResources = () => {
  const {
    allDatoCmsResource,
    allDatoCmsBlogPost,
    allDatoCmsCaseStudy,
    allDatoCmsPageTemplateVideoDetail,
  } = useStaticQuery(resourcesQuery);

  const blogPosts = allDatoCmsBlogPost.edges.map(blogPost =>
    formatBlogPost(blogPost.node)
  );

  const caseStudies = allDatoCmsCaseStudy.edges.map(caseStudy =>
    formatCaseStudy(caseStudy.node)
  );

  const datoResources = allDatoCmsResource.edges.map(datoResource =>
    formatDatoResource(datoResource.node)
  );

  const websiteVideos = allDatoCmsPageTemplateVideoDetail.edges.map(video =>
    formatVideoTemplate(video.node)
  );

  const resources = [
    ...blogPosts,
    ...caseStudies,
    ...websiteVideos,
    ...manualResources,
    ...datoResources,
  ].sort((a, b) => {
    const date1 = new Date(a.publishDate ? a.publishDate : 0);
    const date2 = new Date(b.publishDate ? b.publishDate : 0);

    return date2 - date1;
  });

  const recommendedResources = resources.filter(
    resource => resource?.isRecommended
  );

  return {
    blogPosts,
    resources,
    recommendedResources,
  };
};

export default useResources;

const resourcesQuery = graphql`
  query {
    allDatoCmsResource {
      edges {
        node {
          id
          title
          internalName
          isRecommended
          excerpt
          featuredImage {
            gatsbyImageData(
              imgixParams: {
                fm: "webp"
                auto: "compress"
                maxW: 1080
                fit: "clip"
                q: 35
              }
            )
          }
          publishDate
          slug
          announcement {
            id
            pageTitle
          }
          headHtml
          tags {
            name
          }
        }
      }
    }
    allDatoCmsBlogPost {
      edges {
        node {
          ...datoCmsBlogPost
        }
      }
    }
    allDatoCmsCaseStudy {
      edges {
        node {
          ...datoCmsCaseStudy
          contentWordCounter
          aboutCompanyWordCounter
          publishDate
          meta {
            publishedAt
          }
        }
      }
    }
    allDatoCmsPageTemplateVideoDetail {
      edges {
        node {
          ...datoCmsPageTemplateVideoDetail
        }
      }
    }
  }
`;
